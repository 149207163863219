export const NavItems = [
  // {
  //   title: 'Artwork',
  //   link: '#artwork',
  //   cName: 'nav-links'
  // },
  {
    title: 'About',
    link: '#about',
    cName: 'nav-links'
  },
  // {
  //   title: 'Blog',
  //   link: 'blog',
  //   cName: 'nav-links'
  // },
  // {
  //   title: 'Recommendations',
  //   link: '#recommendations',
  //   cName: 'nav-links'
  // },
  // {
  //   title: 'Donate',
  //   link: '#donate',
  //   cName: 'nav-links'
  // },
  {
    title: 'Contact',
    link: '#contact',
    cName: 'nav-links'
  }
]